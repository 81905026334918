var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "video-detail-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "d-flex align-center cursor-pointer mb-10",
        on: { click: _vm.goBack },
      },
      [
        _c("AppIcon", {
          staticClass: "mr-2",
          attrs: {
            icon: "arrow-left",
            color: "var(--v-text-gray-base)",
            width: "18",
          },
        }),
        _c("span", { staticClass: "back-text" }, [
          _vm._v(_vm._s(_vm.$t("videos.backToVideos"))),
        ]),
      ],
      1
    ),
    _c("video", {
      attrs: { src: `${_vm.item.video}#t=0.2`, width: "100%", controls: "" },
    }),
    _c("div", { staticClass: "video-detail-title mt-4" }, [
      _vm._v(_vm._s(_vm.item.title)),
    ]),
    _c("div", { staticClass: "video-detail-created-date mt-4" }, [
      _vm._v(_vm._s(_vm._f("formatDate")(_vm.item.date))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }