<template>
  <div class="video-detail-wrapper">
    <div class="d-flex align-center cursor-pointer mb-10" @click="goBack">
      <AppIcon icon="arrow-left" color="var(--v-text-gray-base)" width="18" class="mr-2" />
      <span class="back-text">{{ $t('videos.backToVideos') }}</span>
    </div>
    <video :src="`${item.video}#t=0.2`" width="100%" controls></video>
    <div class="video-detail-title mt-4">{{ item.title }}</div>
    <div class="video-detail-created-date mt-4">{{ item.date | formatDate }}</div>
  </div>
</template>
<script>
export default {
  name: 'VideoDetail',
  data() {
    return {
      item: {},
    };
  },
  created() {
    if (!this.$route.params?.item) {
      this.$router.push({ name: 'Videos' });
    } else {
      this.item = { ...this.$route.params?.item };
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'Videos',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.video-detail-wrapper {
  .back-text {
    color: var(--v-text-gray-base);
    font-size: 13px;
    line-height: 15px;
  }
  .video-detail-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
  }
  .video-detail-created-date {
    color: var(--v-dark-gray-base);
    font-weight: 500;
    font-size: 8px;
    line-height: 9px;
  }
}
</style>
